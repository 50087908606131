<template>
    <div class="body-container">
        <BannerSection title="Préstamos con mal historial crediticio en México"
                       subtitle="Obtén financiamiento sin complicaciones, incluso con historial crediticio negativo. Conoce nuestros préstamos con mal buró" />
        <PerksComponent></PerksComponent>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p>¿Sabías que tener un mal historial crediticio no significa que no puedas acceder a un préstamo?</p>
                    <p>
                        Aunque muchos piensan que estar en el buró de crédito cierra todas las puertas, en realidad existen soluciones diseñadas especialmente para personas en esta situación. 
                    </p>
                    <p>
                        Sigue leyendo y descubre cómo los <b>préstamos con mal historial crediticio</b> pueden ayudarte a superar tus problemas financieros.
                    </p>
                    <br>
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">¿Qué es un historial crediticio?</h3>
                    </span>
                    <p>
                        Tu <b>historial crediticio</b> es un registro que refleja cómo has manejado tus deudas a lo largo del tiempo. Este historial se utiliza para calcular tu <b>score de crédito</b>, una calificación que los bancos y prestamistas emplean para decidir si te aprueban un préstamo y bajo qué condiciones.
                    </p>
                    <ul>
                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Score alto:
                            </p>
                            <span> acceso a mejores tasas de interés y condiciones flexibles.</span>
                        </li>
                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Score bajo
                            </p>
                            <span>tasas más altas y términos más estrictos, o incluso rechazos.</span>
                        </li>
                        <br>
                    </ul>
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">¿Qué pasa si tienes un mal historial crediticio?</h3>
                    </span>
                    <p>
                        Un <b>mal historial</b> no es el fin del mundo. Aunque limita las opciones en bancos tradicionales, en Ibancar ofrecemos una solución personalizada a <b>préstamos con mal historial</b>. En lugar de basarnos únicamente en tu score, analizamos tu caso de manera integral.
                    </p>
                    <br>
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">Préstamos con mal historial crediticio en México</h3>
                    </span>
                    <p>
                        En México, los <b>préstamos con mal historial crediticio</b> están ganando popularidad porque permiten a muchas personas resolver emergencias financieras. Estas opciones están diseñadas para quienes han tenido dificultades económicas, pero aún necesitan apoyo para avanzar.
                    </p>
                    <br>
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">¿Cómo funcionan?</h3>
                    </span>
                    <ul>
                        <li>
                            <p class="mb-0 bold-text">
                                Revisión personalizada:
                            </p>
                            <span>Analizamos tu situación al detalle, incluyendo otras formas de garantía, como puede ser tu auto.</span>
                        </li>
                        <li>
                            <p class="mb-0 bold-text">
                                Garantías alternativas:
                            </p>
                            <span>Puedes usar tu coche como garantía sin limitaciones.</span>
                        </li>
                        <li>
                            <p class="mb-0 bold-text">
                                Aprobación flexible: 
                            </p>
                            <span>Nos adaptamos a tu situación para ofrecerte algo acorde a ella.</span>
                        </li>
                    </ul>

                </div>
            </div>
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">Ibancar: una solución a tu medida</h3>
                    </span>
                    <p>
                        <b>Ibancar</b> es una de las mejores opciones para obtener un préstamo si tienes un <b>mal historial crediticio</b>. Aquí no dejamos que tu calificación en el buró defina tu futuro, sino que analizamos tu situación actual para ofrecerte una solución adecuada.
                    </p>
                </div>
            </div>
        </div>

        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h3 style="font-size: 20px;">Ventajas de elegir Ibancar</h3>
                    </span>
                    <ul>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Rápido y sencillo:
                            </h4>
                            <span>Todo el proceso es online, con respuesta en 24 horas.</span>
                        </li>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Sin perder tu vehículo:
                            </h4>
                            <span>Usa tu coche como garantía mientras sigues manejando.</span>
                        </li>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Sin discriminación por el historial:
                            </h4>
                            <span>Se aceptan solicitudes incluso con mal buró de crédito.</span>
                        </li>
                        <br>
                    </ul>

                    <span class="bold-text">
                        <br>
                        <h3 style="font-size: 20px;">¿Cómo solicitar un préstamo con mal historial en Ibancar?
                        </h3>
                        
                    </span>
                    <ol>
                        <li>
                            <p><b>Completa el formulario online: </b></p>
                            <span>Accede a nuestro sitio web y proporciona tus datos personales y del vehículo. </span>
                        </li>

                        <br>
                        <li>
                            <p><b>Envía la documentación requerida:</b></p>
                            <span> Esto incluye tu identificación, comprobantes de ingresos y domicilio y documentos del coche.</span>
                        </li>
                        <br>

                        <li>
                            <p><b>Espera la aprobación:</b></p>
                            <span> En cuestión de  minutos recibirás una respuesta. Si se aprueba, el dinero se deposita en tu cuenta de inmediato.</span>
                        </li>
                        <br>
                    </ol>

                    <span class="bold-text">
                        <br>
                        <h3 style="font-size: 20px;">Preguntas frecuentes (FAQs)</h3>
                    </span>
                    <div>
                        <h3 style="font-size: 20px; font-style: italic;">-  ¿Puedo obtener un préstamo si estoy en el buró de crédito?</h3>
                        <p style="font-size: 17px;">Sí, en Ibancar ofrecemos préstamos incluso si tienes un mal historial crediticio. Lo importante es demostrar que eres titular de un vehículo y tienes algo que acredite su propiedad.</p>
                    </div>
                    <br>
                    <div>
                        <h3 style="font-size: 20px; font-style: italic;">- ¿Es seguro usar mi coche como garantía?</h3>
                        <p style="font-size: 17px;">Totalmente. En Ibancar, puedes seguir usando tu vehículo mientras dure el préstamo. Además, no se cambia la titularidad del coche.</p>
                    </div>
                    <br>
                    <div>
                        <h3 style="font-size: 20px; font-style: italic;">-  ¿Qué tan rápido puedo recibir el dinero?</h3>
                        <p style="font-size: 17px;">En la mayoría de los casos, el dinero se deposita en menos de 24 horas tras completar tu solicitud.</p>
                    </div>
                    <br>
                    <p>
                        En Ibancar, estamos aquí para darte una segunda oportunidad financiera. Sabemos que un <b>mal historial crediticio</b> no define tus necesidades, por eso ofrecemos préstamos con <b>mal historial diseñados </b> para ayudarte, sin importar tu situación en el buró. 
                    </p>
                    <p>
                        Si necesitas dinero, somos la alternativa que buscas. ¡Da el primer paso y solicita el préstamo que necesitas con nosotros hoy mismo!
                    </p>
                    <br>
                    <br>
                </div>
            </div>
        </div>

        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamosConMalHistorial',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Prestamos con mal historial crediticio en México',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: '¿Tienes un mal historial crediticio? En Ibancar ofrecemos préstamos accesibles en México, incluso si estás en el buró de crédito. Descubre cómo obtener un préstamo personalizado con tu coche como garantía. ¡Solicita hoy y recibe respuesta en 24 horas!'
                    },
                    {
                        name: 'keywords',
                        content: 'prestamos con mal historial - prestamos con mal historial crediticio - préstamos con mal historial crediticio méxico '
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/prestamos-sin-buro', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>